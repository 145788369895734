import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/program-dla-malej-firmy-baner-1503x1080.png";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "Program-dla-malej-firmy-wordpress-768x480.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "Program-dla-malej-firmy-canva-768x480.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: { eq: "Program-dla-malej-firmy-brand24-768x480.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "program-dla-malej-firmy-quickcrm-768x480.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: { eq: "program-dla-malej-firmy-analytics-768x444.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const ProgramDlaMałejFirmy = ({ data }) => {
  return (
    <Artykul
      title="5 narzędzi online, które pozwolą Twojej firmie ruszyć z kopyta"
      articleImage={obrazekArtykulu}
      keywords={["program dla małej firmy"]}
      articleImageAlt="Program dla małej firmy"
      metaTitle="5 narzędzi online, które pozwolą firmie ruszyć z kopyta"
      metaDescription="✅ Program dla małej firmy - które są szczególnie przydatne? Jak zadbać o relacje z klientami? Poznaj internetowe narzędzia, które pomogą Ci rozwinąć biznes • CRM dla firm • QuickCRM - 30 dni za darmo!"
    >
      <br />
      <p>
        W Twojej głowie właśnie zapadła decyzja o założeniu własnej firmy?
        Świetnie! Teraz pozostało już tylko wybrać rodzaj działalności i można
        ruszać na podbój rynku. Konkurencja jest czujna, więc już od samego
        początku musisz korzystać z narzędzi, które pomogą Ci być zawsze o krok
        przed nią. W tym artykule opowiemy o najważniejszych internetowych
        narzędziach, dzięki którym Twój raczkujący biznes stanie na nogi i
        ucieknie konkurencji.
      </p>
      <h2>Jakie narzędzia dla małej firmy?</h2>
      <br />
      <h3>1. WordPress</h3>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="program dla małej firmy"
        alt="program dla małej firmy"
        fluid={data.zdjecie1.childImageSharp.fluid}
      />
      <p>
        Strona internetowa jest wirtualnym odzwierciedleniem Twojego pomysłu,
        opowiadaj o nim w piękny sposób. To właśnie idea decyduje o sukcesie
        firmy, dlatego jej wizytówka powinna wyglądać jak najlepiej, przy
        zachowaniu maksymalnej czytelności. Musisz upewnić się, że informacje,
        które prezentujesz są rzetelne i pozostawiają potencjalnego klienta bez
        cienia wątpliwości na temat Twojej oferty. Tu z pomocą przychodzi
        WordPress - bezpłatne (w podstawowym planie) narzędzie do tworzenia
        stron internetowych. Zdaniem analityków IT prawie 28% stron
        internetowych w 2017 roku opartych jest na WordPressie. Wynika to
        głównie z łatwości zbudowania własnej strony - nie jest wymagana
        znajomość HTML ponieważ WordPress opiera się na filozofii WYSIWYG (ang.
        what you see is what you get).
      </p>
      <cite>
        <strong>
          "Strona internetowa jest wirtualnym odzwierciedleniem Twojego pomysłu,
          opowiadaj o nim w piękny sposób."
        </strong>
      </cite>
      <br />
      <br />
      <h3>2. Canva</h3>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="program dla małej firmy"
        alt="program dla małej firmy"
        fluid={data.zdjecie2.childImageSharp.fluid}
      />
      <p>
        Jeśli mowa o pięknych i czytelnych treściach, to warto wspomnieć o
        Canvie. Jest to proste i bardzo intuicyjne narzędzie to tworzenia grafik
        metodą drag&drop. Przygotujemy tu logo, grafiki na stronę internetową,
        czy infografiki do artykułów na blogu. Aplikacja udostępnia szereg
        funkcji, które pozwalają kadrować zdjęcia, dodawać ikony, grafiki oraz
        tekst. Możemy także zmieniać kolorystykę grafik i nakładać filtry.
        Wszystkie operacje sprowadzają się do kilku kliknięć i gotowe! Okazuje
        się, że nie musisz być ekspertem od Photoshopa, żeby projektować dobrze
        wyglądające treści. Canva w podstawowym planie jest całkowicie darmowa.
        Oprócz wersji online dostępna jest także aplikacja na iPhone’a oraz
        iPada.
      </p>
      <br />
      <cite>
        <strong>
          “Okazuje się, że nie musisz być ekspertem od Photoshopa, żeby
          projektować dobrze wyglądające treści.”
        </strong>
      </cite>
      <br />
      <br />
      <h3>3. Brand24</h3>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="program dla małej firmy"
        alt="program dla małej firmy"
        fluid={data.zdjecie3.childImageSharp.fluid}
      />
      <p>
        Zżera Cię ciekawość, co na temat Twojej marki/produktu mówią inni?
        Chcesz dowiedzieć się jakie działania w Internecie podejmuje
        konkurencja? Za wszelką cenę musisz poznać trendy związane są aktualnie
        z tematyką Twojego biznesu? Z pomocą przyjdzie Ci Brand24. To naprawdę
        świetne narzędzie do monitoringu Internetu. Możesz wpisać interesujące
        Cię dane i już po chwili otrzymasz analizę frazy wraz z wykresem. W
        ramach testów masz możliwość korzystania z systemu bezpłatnie przez 14
        dni, później podstawowy plan to koszt 149zł miesięcznie.
      </p>
      <br />
      <br />
      <h3>4. QuickCRM</h3>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="program dla małej firmy"
        alt="program dla małej firmy"
        fluid={data.zdjecie4.childImageSharp.fluid}
      />
      <br />
      <p>
        Według badań 86% klientów nie podejmuje ponownie interakcji z firmą,
        która zawiodła ich słabym doświadczeniem zakupowym. To znak, że Twoja
        firma powinna zadbać o porządek w kontaktach z klientami już od samego
        początku. Raz zaniedbany i utracony klient to spore koszty, ponieważ
        znacznie taniej jest utrzymać stałego klienta, niż pozyskać nowego.
        Podstawowym narzędziem, które pomoże nam zapanować nad relacjami z
        klientami jest QuickCRM. Ten prosty program dla małej firmy potrafi
        jednak kilka dodatkowych rzeczy, które sprawdzą się doskonale w Twoim
        biznesie. Mam tu na myśli kalendarz z funkcją delegowania zadań,
        wewnętrzny komunikator, raportowanie sprzedaży oraz zarządzanie szansami
        sprzedaży. Dzięki tej ostatniej możesz analizować proces sprzedażowy,
        kontrolować poszczególne etapy i wartości szans. QuickCRM przypomni Ci o
        zadaniach powiązanych z konkretnym klientem, dlatego już nigdy nie
        przeoczysz ważnych spraw. Podstawowy pakiet Start to koszt 5 zł
        miesięcznie za jednego użytkownika.
      </p>
      <cite>
        <strong>
          “Raz zaniedbany i utracony klient to spore koszty, ponieważ znacznie
          taniej jest utrzymać stałego klienta, niż pozyskać nowego.”
        </strong>
      </cite>
      <br />
      <br />
      <br />
      <h3>5. Google Analytics</h3>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="program dla małej firmy"
        alt="program dla małej firmy"
        fluid={data.zdjecie5.childImageSharp.fluid}
      />
      <br />
      <p>
        Na końcu tego zestawienia znalazło się potężne narzędzie od Google.
        Potęga może sprawiać wrażenie, że nie jest to program dla małej firmy.
        Na szczęście to tylko złudne odczucie. Analytics, jak sama nazwa
        wskazuje, to rozwiązanie, które pozwala na analitykę internetową strony
        Twojej firmy. Z czasem będzie ona odwiedzana coraz częściej i dlatego
        dobrze wiedzieć skąd pochodzi ruch, jakie działania w Internecie są
        skuteczne, które kanały reklamowe najlepiej przekładają się na
        pozyskiwanie klientów. W Google Analytics masz możliwość wygenerowania
        około 80 rodzajów raportów oraz tworzenia segmentów użytkowników na
        podstawie źródeł ruchu i zachowań na stronie. Narzędzie jest bezpłatne,
        o ile Twoja strona nie jest odwiedzana częściej niż 5 milionów razy
        miesięcznie.
      </p>
      <br />
      <p>
        Na koniec tego artykułu życzymy Ci, drogi przedsiębiorco, aby narzędzia,
        o których mowa powyżej, przyczyniły się do rozwoju Twojego biznesu do
        tego stopnia, żeby Google Analytics przestał być dla niego bezpłatny{" "}
        <span role="img" aria-label="mrugająca buźka">
          😉
        </span>
      </p>

    </Artykul>
  );
};

export default ProgramDlaMałejFirmy;
